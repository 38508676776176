<template>
  <div>
    <v-dialog v-model="fbdialog" width="650">
      <v-card>
        <v-card-title class="bluegrad white--text">
          Connect Facebook Leads
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon color="white">
              mdi-close-circle
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-3">
          <v-row>
            <v-col cols="4">
              <h3 class="mt-3">Page</h3>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="selectedPage"
                :items="pageList"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="py-2"
                return-object
                @change="fetchLeadgenForm"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3 class="mt-3">Form</h3>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="selectedForm"
                :items="formList"
                item-text="name"
                item-value="id"
                outlined
                dense
                return-object
                @change="fbLeadFormFields"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3 class="mt-3">List</h3>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="selectedList"
                :items="lists"
                item-text="name"
                item-value="id"
                outlined
                dense
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-expansion-panels elevation="0" class="px-3 py-1">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Advanced Options
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card elevation="0">
                <v-card-text>
                  <h1 class="text-center mb-4 d-map-head">
                    Map Fields
                  </h1>

                  <v-row align="center">
                    <v-col cols="12">
                      <!-- Default Fields -->
                      <div
                        class="d-flex justify-space-evenly align-center map-data"
                        width="100%"
                      >
                        <div class="blocks">
                          <h3 class="mx-2">Name</h3>
                        </div>
                        <div class="blocks">
                          <p class="mx-2">map data with</p>
                        </div>
                        <div class="blocks">
                          <v-text-field
                            class="mx-4 selects"
                            label="Facebook Field"
                            value="full_name"
                            disabled
                          ></v-text-field>
                        </div>
                      </div>
                      <!-- Default Fields -->
                      <div
                        class="d-flex justify-space-evenly align-center map-data"
                        width="100%"
                      >
                        <div class="blocks">
                          <h3 class="mx-2">Phone</h3>
                        </div>
                        <div class="blocks">
                          <p class="mx-2">map data with</p>
                        </div>
                        <div class="blocks">
                          <v-text-field
                            class="mx-4 selects"
                            label="Facebook Field"
                            value="phone_number"
                            disabled
                          ></v-text-field>
                        </div>
                      </div>
                      <!-- ! Other Fields -->
                      <div
                        v-for="(item, index) in additionalItems"
                        :key="index"
                        class="d-flex justify-space-evenly align-center map-data"
                        width="100%"
                      >
                        <div class="blocks">
                          <!-- <h3 class="mx-2">Notes</h3> -->
                          <v-select
                            v-model="item.godialKey"
                            class="mr-4 selects"
                            :items="gdItems"
                            label="Godial Field"
                          ></v-select>
                        </div>
                        <div class="blocks">
                          <p class="mx-2">map data with</p>
                        </div>
                        <div class="blocks">
                          <v-select
                            v-if="fbItemsFields.length > 0"
                            v-model="item.fbKey"
                            class="mx-2"
                            :items="fbItemsFields"
                            item-text="text"
                            item-value="value"
                            label="Facebook Field"
                            variant="outlined"
                          ></v-select>
                        </div>
                        <v-icon @click="removeSection(index)"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-card-action
                    class="d-flex justify-center align-center mt-5 mb-2"
                  >
                    <v-btn color="purple white--text" @click="addField"
                      >Add field</v-btn
                    >
                    <!-- <v-btn class="mx-4" color="primary" @click="updateFieldSelection"
                >Update</v-btn
              >
              <v-btn @click="cancelField">Cancel</v-btn> -->
                  </v-card-action>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions class="d-flex pa-3">
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn depressed dark class="bluegrad" @click="saveFbLeadsToList"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["lists"],
  data() {
    return {
      pageList: [],
      selectedPage: {},
      selectedForm: {},
      formList: [],
      selectedList: {},
      fbdialog: false,
      loader: false,
      gdItems: [],
      fbItemsFields: [],
      additionalItems: [],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "teams", "fieldList", "selectedTeam"]),
  },
  async created() {
    this.fetchForm();
  },
  methods: {
    fetchForm() {
      var staticFields = [
        "secondPhone",
        "email",
        "companyName",
        "address",
        "extra",
        "remarks",
        "note",
      ];
      var customFields = this.fieldList;

      var result = staticFields.concat(
        customFields.map((field) => `customFields[${field.name}]`)
      );

      this.gdItems = result;
    },
    removeSection(index) {
      this.additionalItems.splice(index, 1);
    },
    addField() {
      this.additionalItems.push({
        godialKey: "",
        fbKey: "",
      });
    },
    open() {
      this.logInWithFacebook();
    },
    async logInWithFacebook() {
      const self = this;
      window.FB.login(
        function(response) {
          if (response.authResponse) {
            self.shortAccessToken = response.authResponse.accessToken;
            self.getLongLivedAccessToken();
            self.fbdialog = true;
          } else {
            console.log("User cancelled login or did not fully authorize.");
            self.fbdialog = false;
          }
        },
        {
          scope:
            "email,public_profile,pages_show_list,ads_management,pages_read_engagement,pages_manage_metadata,pages_manage_ads,ads_read,leads_retrieval,business_management",
          return_scopes: true,
        }
      );

      return false;
    },
    subscribedApp(page) {
      const self = this;
      return new Promise((resolve, reject) => {
        window.FB.api(
          `/${page.id}/subscribed_apps`,
          "post",
          {
            access_token: page.access_token,
            subscribed_fields: ["leadgen"],
          },
          function(respo) {
            console.log("Subscribe FB Success----------->", respo);
            resolve({ res: "without app token" });
          }
        );
      });
    },
    subscribedLeadgenWebhook() {
      const self = this;
      // Check After Live Test
      return new Promise((resolve, reject) => {
        window.FB.api(
          "/740864193492825/subscriptions",
          "post",
          {
            object: "page",
            callback_url: process.env.VUE_APP_FBCALLBACKURL,
            fields: "leadgen",
            verify_token: process.env.VUE_APP_VERIFYTOKEN,
            access_token: process.env.VUE_APP_APPACCESSTOKEN,
          },
          function(respo) {
            resolve();
          }
        );
      });
    },
    async saveFbLeadsToList() {
      if (Object.keys(this.selectedPage).length === 0) {
        return this.$swal({
          type: "error",
          text:
            "Please Select A Facebook Page or You did't have any Facebook Page.",
        });
      }

      if (Object.keys(this.selectedForm).length === 0) {
        return this.$swal({
          type: "error",
          text:
            "Please Select A Facebook Form or You did't have any Form, Please Create a New Facebook Form.",
        });
      }

      if (Object.keys(this.selectedList).length === 0) {
        return this.$swal({
          type: "error",
          text:
            "Please Select A List for Leads Capture. If you didn't have any list, please create a list from list page.",
        });
      }

      const mappingObject = [
        { godialKey: "name", fbKey: "full_name" },
        { godialKey: "phone", fbKey: "phone_number" },
        { godialKey: "phone", fbKey: "phone" },
        ...this.additionalItems,
      ];

      console.log("mapping Object", mappingObject);

      try {
        this.loader = true;
        let respo = await this.$http.post(`${this.ENDPOINT}/integrations`, {
          type: "fbLeads",
          enabled: true,
          companyId: this.$store.getters.companyDetails.id,
          listId: this.selectedList.id,
          fieldMapping: mappingObject,
          config: {
            listName: this.selectedList.name,
            formId: this.selectedForm.id,
            formName: this.selectedForm.name,
            pageId: this.selectedPage.id,
            pageName: this.selectedPage.name,
            pageAccessToken: this.selectedPage.access_token,
          },
        });
        respo = respo.body;
        await this.subscribedLeadgenWebhook();
        await this.subscribedApp(this.selectedPage);
        this.close();
        this.loader = false;
        this.$swal({
          type: "success",
          text: "Webhook Setup Successfully.",
        });

        try {
          fb.log("fb_lead_add");
        } catch (e) {
          console.log("Error in FB log", e);
        }
      } catch (error) {
        let message = error.body.error.message.includes("duplicate")
          ? "Same List & Page Can't be Added Again."
          : "Something Went Wrong";

        this.loader = false;
        this.$swal({
          type: "error",
          text: message,
        });
      }
    },
    getLongLivedAccessToken() {
      const self = this;

      window.FB.api(
        `/oauth/access_token`,
        "get",
        {
          grant_type: "fb_exchange_token",
          client_id: process.env.VUE_APP_FBAPPID,
          client_secret: process.env.VUE_APP_FBCLIENTSECRET,
          fb_exchange_token: self.shortAccessToken,
        },
        function(respo) {
          self.longAccessToken = respo.access_token;
          self.getFbPages();
        }
      );
    },
    fbLeadFormFields() {
      var self = this;
      self.fbItemsFields = [];
      window.FB.api(
        `/v18.0/${self.selectedForm.id}?fields=id,name,questions`,
        "get",
        {
          access_token: this.selectedPage.access_token,
        },
        function(response) {
          var fbFieldItems = [];

          // Use the map function to transform response.questions array
          fbFieldItems = response.questions.map((question) => ({
            text: question.label,
            value: question.key,
          }));

          self.fbItemsFields = fbFieldItems;
        }
      );
    },
    fetchLeadgenForm() {
      const self = this;

      window.FB.api(
        `/v18.0/${this.selectedPage.id}/leadgen_forms`,
        "get",
        {
          access_token: this.selectedPage.access_token,
        },
        function(response) {
          // console.log("response---Form--->", response);
          self.formList = response.data.filter((f) => {
            if (f.status != "ARCHIVED") return f;
          });
          // console.log("formlist", self.formList);
          if (self.formList.length > 0) {
            self.selectedForm = self.formList[0];
          }

          setTimeout(function() {
            self.fbLeadFormFields();
          }, 200);
        }
      );
    },
    getFbPages() {
      const self = this;
      window.FB.api(
        `/me/accounts?fields=name,access_token`,
        "get",
        { access_token: this.longAccessToken },
        function(response) {
          console.log("Get FB Pages----------->", response);
          if (self.lists.length > 0) {
            self.selectedList = self.lists[0];
          }
          self.pageList = response.data;
          if (self.pageList.length > 0) {
            self.selectedPage = self.pageList[0];
          }
          self.fetchLeadgenForm();
          // self.fbLeadFormFields();
          self.fbdialog = true;
        }
      );
    },
    // getFbPages() {
    //   const self = this;
    //   window.FB.api(
    //     `/me?fields=id,name,businesses{pages{access_token,name,id}}`,
    //     "get",
    //     { access_token: this.longAccessToken },
    //     function (response) {
    //       console.log("Get FB Pages and Businesses----------->", response);
    //       const pages = [];
    //       // Add personal pages
    //       if (response.accounts && response.accounts.data) {
    //         pages.push(...response.accounts.data);
    //       }
    //       // Add pages from businesses
    //       if (response.businesses && response.businesses.data) {
    //         response.businesses.data.forEach(business => {
    //           if (business.pages && business.pages.data) {
    //             pages.push(...business.pages.data);
    //           }
    //         });
    //       }

    //       self.pageList = pages;
    //       if (self.pageList.length > 0) {
    //         self.selectedPage = self.pageList[0];
    //         self.fetchLeadgenForm();
    //       }
    //       if (self.lists.length > 0) {
    //         self.selectedList = self.lists[0];
    //       }
    //       self.fbdialog = true;
    //     }
    //   );
    // },

    close() {
      this.fbdialog = false;
      this.$emit("refreshData");
    },
  },
};
</script>

<style scoped>
.blocks {
  width: 32%;
}

.selects {
  /* max-width: 30%; */
}

.map-data {
  width: 100%;
}

.map-data p {
  margin: 0;
}
</style>

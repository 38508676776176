<template>
  <v-container fluid>
    <v-layout row wrap class="ma-0">
      <v-flex xs12 class="ma-md-10">
        <v-card class="py-5 mx-md-15 rounded">
          <v-card-title>
            <v-row style="margin:0px !important">
              <v-flex xs12 sm10> </v-flex>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row style="margin:0px !important">
              <v-flex xs12 sm2>
                <div align="center">
                  <v-icon class="px-2 customPadding" color="#4267B2" style="font-size: 120px;">mdi-facebook</v-icon>
                </div>
              </v-flex>
              <v-flex xs12 sm8>
                <p style="font-size: 24px; font-weight: 500; letter-spacing: 0.8px;line-height: 1.6; opacity: 0.9">
                  Connect your facebook lead add to your GoDial account and
                  instantly receive leads in your list
                </p>
                <div style="font-size: 18px;">
                  <p>
                    1. You will need to give Lead Access to GoDial,
                    <a href="https://www.facebook.com/business/help/618808448980683" target="_blank">follow steps
                      here</a>
                  </p>

                  <p>
                    2. Click add and authenticate with facebook.
                  </p>
                  <p>
                    3. Select the page you want to integrate and give
                    permission.
                  </p>
                  <p>
                    4. Choose the list where you want to receive the data and
                    click save.
                  </p>
                </div>
              </v-flex>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="ma-0">
      <v-flex xs12 class="ma-md-10">
        <v-card class="py-5 mx-md-15 rounded" elevation="0">
          <v-row class="pa-0 ma-0">
            <v-col v-for="(item, i) in fbLeadsWithLists" :key="i" class="pa-1 ma-0" cols="12" sm="4" md="3">
              <v-card class="rounded ma-1 fill-height">
                <v-card-title class="pa-2 bluegrad white--text">
                  <v-icon class="px-2" color="white">mdi-facebook</v-icon>
                  {{ item.config.pageName }}
                  <v-spacer></v-spacer>
                  <v-btn icon @click="confirmDelete(item)">
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="my-0 pa-2">
                  <h4>
                    List:
                    <span class="pl-6 font-weight-medium secondary--text text--lighten-3">
                      {{ item.config.listName }}
                    </span>
                  </h4>
                  <h4>
                    Form:
                    <span class="pl-3 font-weight-medium secondary--text text--lighten-3">
                      {{ item.config.formName }}
                    </span>
                  </h4>
                  <h4>
                    <span class="pl-3 font-weight-medium secondary--text text--lighten-3">
                      <v-switch v-model="item.enabled" flat :label="item.enabled ? 'On' : 'Off'"
                        @click="toogleFbleadsConn(item)"></v-switch>
                    </span>
                  </h4>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="pa-1 ma-0" cols="12" sm="4" md="3">
              <v-card class="secondarygrad d-flex align-center rounded ma-1 fill-height" @click="fbLeadsPopupOpen()">
                <v-card-text class=" d-flex justify-center ">
                  <!-- <h3 class="font-weight-medium">
              + Add
            </h3> -->
                  <button class="loginBtn loginBtn--facebook">
                    Add
                  </button>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <fb-leads-dialog ref="fbLeadsPopup" :lists="lists" @refreshData="refresh"></fb-leads-dialog>
  </v-container>
</template>
<script>
import fbLeadsDialog from "../../components/fbLeadsDialog.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: { fbLeadsDialog },
  data() {
    return {
      shortAccessToken: "",
      longAccessToken: "",
      lists: [],
      fbLeadsWithLists: [],
      loader: false,
    };
  },
  async created() {
    await this.init();
  },
  computed: {
    ...mapGetters(["ENDPOINT", "fieldList", "teams", "selectedTeam"]),
  },
  methods: {
    fbLeadsPopupOpen() {
      this.$refs.fbLeadsPopup.open();
    },
    async init() {
      await this.getFbleadsIntegrationList();
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      await this.getListByTeams();
    },
    confirmDelete(integration) {
      this.$swal({
        title: "Are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#f44336",
        confirmButtonText: "Yes, Remove it!",
      }).then(async (willDelete) => {
        if (willDelete.value) {
          this.loader = true;

          await this.unSubscribeApp(integration.config);
          await this.deleteConfig(integration);

          try {
            fb.log("fb_lead_delete");
          } catch (e) {
            console.log("Error in FB log", e);
          }
          this.loader = false;
        }
      });
    },
    unSubscribeApp(config) {
      return new Promise((resolve, reject) => {
        FB.api(
          "/" + config.pageId + "/subscribed_apps",
          "delete",
          { access_token: config.pageAccessToken },
          function (response) {
            resolve();
          }
        );
      });
    },
    deleteConfig(integration) {
      return new Promise((resolve, reject) => {
        this.$http
          .delete(`${this.ENDPOINT}/integrations/${integration.id}`)
          .then((respo) => {
            this.getFbleadsIntegrationList();
            resolve();
          })
          .catch((err) => {
            console.log("Error On Delete", err);
            reject();
          });
      });
    },
    getFbleadsIntegrationList() {
      this.loader = true;
      let filter = {
        where: {
          type: "fbLeads",
          companyId: this.$store.getters.companyDetails.id,
        },
      };
      this.$http
        .get(
          `${this.ENDPOINT}/integrations?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        )
        .then((respo) => {
          this.loader = false;
          this.fbLeadsWithLists = respo.body;
        })
        .catch((err) => {
          console.log("Error", err);
          this.fbLeadsWithLists = [];
          this.loader = false;
        });
    },

    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async getListByTeams() {
      this.lists = [];

      var teamIds = this.getTeamIds();

      // Teams sections
      let response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
        teams: teamIds,
      });
      this.lists = response.body;

      if (this.lists.length < 1) {
        this.lists = [];
        return;
      }
    },

    async initFacebook() {
      const self = this;
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FBAPPID, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v18.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    toogleFbleadsConn(integrationObj) {
      this.loader = true;
      integrationObj.modifiedOn = new Date();
      this.$http
        .put(
          `${this.ENDPOINT}/integrations/${integrationObj.id}`,
          integrationObj
        )
        .then((respo) => {
          this.getFbleadsIntegrationList();
          this.loader = false;
        })
        .catch((err) => {
          this.loader = false;
          console.log("Error In Patch Integration Doc", err);
        });
    },
    refresh() {
      this.getFbleadsIntegrationList();
    },
  },
};
</script>
<style scoped>
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

@media (min-width: 1200px) {
  .customPadding {
    padding-top: 25%;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .customPadding {
    padding-top: 20%;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .customPadding {
    padding-top: 15%;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .customPadding {
    padding-top: 3%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .customPadding {
    padding-top: 2%;
  }
}
</style>
